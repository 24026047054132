import styled from 'styled-components';

import { Spaces } from '~/styles/Metrics';

export const Container = styled.div`
  max-width: 1080px;
  width: 100%;
  padding: 0 20px;
  margin: ${Spaces.BaseMargin} auto;
  line-height: 26px;
`;
