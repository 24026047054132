import React from 'react';

import Header from '~/components/Header';

import Banner from '~/pages/Projects/Components/Banner';
import Grid from '~/pages/Projects/Components/Grid';
import HorizontalSlider from '~/pages/Projects/Components/HorizontalSlider';
import Nav from '~/pages/Projects/Components/Nav';
import Video from '~/pages/Projects/Components/Video';

import BannerBackground from '~/assets/imgs/budweiser/banner.jpg';
import gridImage01 from '~/assets/imgs/budweiser/grid/01.jpg';
import gridImage02 from '~/assets/imgs/budweiser/grid/02.jpg';
import gridImage03 from '~/assets/imgs/budweiser/grid/03.jpg';
import gridImage04 from '~/assets/imgs/budweiser/grid/04.jpg';
import gridImage05 from '~/assets/imgs/budweiser/grid/05.jpg';
import gridImage06 from '~/assets/imgs/budweiser/grid/06.jpg';
import gridImage07 from '~/assets/imgs/budweiser/grid/07.jpg';
import gridImage08 from '~/assets/imgs/budweiser/grid/08.jpg';
import gridImage09 from '~/assets/imgs/budweiser/grid/09.jpg';
import gridImage10 from '~/assets/imgs/budweiser/grid/10.jpg';
import gridImage11 from '~/assets/imgs/budweiser/grid/11.jpg';
import gridImage12 from '~/assets/imgs/budweiser/grid/12.jpg';
import gridImage13 from '~/assets/imgs/budweiser/grid/13.jpg';
import gridImage14 from '~/assets/imgs/budweiser/grid/14.jpg';
import gridImage15 from '~/assets/imgs/budweiser/grid/15.jpg';
import gridImage16 from '~/assets/imgs/budweiser/grid/16.jpg';
import gridImage17 from '~/assets/imgs/budweiser/grid/17.jpg';
import gridImage18 from '~/assets/imgs/budweiser/grid/18.jpg';
import gridImage19 from '~/assets/imgs/budweiser/grid/19.jpg';
import gridImage20 from '~/assets/imgs/budweiser/grid/20.jpg';
import Mobile01 from '~/assets/imgs/budweiser/mobile01.jpg';
import Mobile02 from '~/assets/imgs/budweiser/mobile02.jpg';
import Mobile03 from '~/assets/imgs/budweiser/mobile03.jpg';
import Mobile04 from '~/assets/imgs/budweiser/mobile04.jpg';
import boomerang01 from '~/assets/video/budweiser/budweiser_boomerang01.webm';
import boomerang02 from '~/assets/video/budweiser/budweiser_boomerang02.webm';
import boomerang03 from '~/assets/video/budweiser/budweiser_boomerang03.webm';
import boomerang04 from '~/assets/video/budweiser/budweiser_boomerang04.webm';

export default function Budweiser() {
  const HeaderConfig = {
    name: 'Budweiser',
    label: 'Deixe que digam',
    job: 'Integrated Campaign',
    description: (
      <>
        <p>
          Budweiser values its brand’s legacy where authenticity was always
          present. For this campaign Budweiser invited consumers to accept their
          true selves without being concerned with other people’s opinion. There
          is no unique way to make beer, just as there is no unique way to live
          life.
        </p>
      </>
    ),
  };
  return (
    <>
      <Header config={HeaderConfig} />
      <Banner src={BannerBackground} alt="Budweiser Deixe que digam 'Banner'" />
      <Grid
        columns={{
          xl: 5,
          lg: 4,
          md: 2,
          sm: 1,
        }}
        list={[
          <img
            src={gridImage01}
            alt="Budweiser Deixe que digam 'Grid de imagens 1'"
          />,
          <img
            src={gridImage02}
            alt="Budweiser Deixe que digam 'Grid de imagens 2'"
          />,
          <img
            src={gridImage03}
            alt="Budweiser Deixe que digam 'Grid de imagens 3'"
          />,
          <img
            src={gridImage04}
            alt="Budweiser Deixe que digam 'Grid de imagens 4'"
          />,
          <img
            src={gridImage05}
            alt="Budweiser Deixe que digam 'Grid de imagens 5'"
          />,
          <img
            src={gridImage06}
            alt="Budweiser Deixe que digam 'Grid de imagens 6'"
          />,
          <img
            src={gridImage07}
            alt="Budweiser Deixe que digam 'Grid de imagens 7'"
          />,
          <img
            src={gridImage08}
            alt="Budweiser Deixe que digam 'Grid de imagens 8'"
          />,
          <img
            src={gridImage09}
            alt="Budweiser Deixe que digam 'Grid de imagens 9'"
          />,
          <img
            src={gridImage10}
            alt="Budweiser Deixe que digam 'Grid de imagens 10'"
          />,
          <img
            src={gridImage11}
            alt="Budweiser Deixe que digam 'Grid de imagens 11'"
          />,
          <img
            src={gridImage12}
            alt="Budweiser Deixe que digam 'Grid de imagens 12"
          />,
          <img
            src={gridImage13}
            alt="Budweiser Deixe que digam 'Grid de imagens 13'"
          />,
          <img
            src={gridImage14}
            alt="Budweiser Deixe que digam 'Grid de imagens 14'"
          />,
          <img
            src={gridImage15}
            alt="Budweiser Deixe que digam 'Grid de imagens 15'"
          />,
          <img
            src={gridImage16}
            alt="Budweiser Deixe que digam 'Grid de imagens 16'"
          />,
          <img
            src={gridImage17}
            alt="Budweiser Deixe que digam 'Grid de imagens 17'"
          />,
          <img
            src={gridImage18}
            alt="Budweiser Deixe que digam 'Grid de imagens 18'"
          />,
          <img
            src={gridImage19}
            alt="Budweiser Deixe que digam 'Grid de imagens 19'"
          />,
          <img
            src={gridImage20}
            alt="Budweiser Deixe que digam 'Grid de imagens 20'"
          />,
        ]}
      />
      <HorizontalSlider
        images={[
          { src: Mobile01, alt: "Budweiser Deixe que digam 'Mobile Post 1'" },
          { src: Mobile02, alt: "Budweiser Deixe que digam 'Mobile Post 2'" },
          { src: Mobile03, alt: "Budweiser Deixe que digam 'Mobile Post 3'" },
          { src: Mobile04, alt: "Budweiser Deixe que digam 'Mobile Post 4'" },
        ]}
      />
      <Grid
        columns={{
          xl: 4,
          md: 2,
          sm: 1,
        }}
        list={[
          <Video src={boomerang01} />,
          <Video src={boomerang02} />,
          <Video src={boomerang03} />,
          <Video src={boomerang04} />,
        ]}
      />
      <Nav prev="/projeto/trident" next="/projeto/inter" />
    </>
  );
}
