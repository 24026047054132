import styled from 'styled-components';

export const Container = styled.div`
  background: url(${props => props.background});
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
`;

export const ToogleImageBox = styled.div`
  display: grid;
  position: relative;
  grid-template-areas: 'image';
`;

export const Image = styled.img`
  grid-area: image;
  bottom: 0%;
  left: 0;
  opacity: 0;
  &.show {
    opacity: 1;
  }
`;
