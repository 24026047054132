import React from 'react';

import PropTypes from 'prop-types';

import Text from '../Text';

import { Container } from './styles';

// TODO Adicionar Key para itens dinâmicos

function Description({ contentList, style }) {
  return (
    <Container style={style}>
      {contentList.map(content => (
        <Text key={Math.random()}>{content}</Text>
      ))}
    </Container>
  );
}

Description.defaultProps = {
  style: {},
};
Description.propTypes = {
  contentList: PropTypes.arrayOf(PropTypes.string).isRequired,
  style: PropTypes.objectOf,
};

export default Description;
