import React from 'react';

import Footer from '~/components/Footer';
import Nav from '~/components/Nav';

import Banner from './Banner';
import Projects from './Projects';
import { Container } from './styles';

export default function Home() {
  return (
    <Container>
      <Nav />
      <Banner />
      <Projects />
      <Footer />
    </Container>
  );
}
