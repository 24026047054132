import React from 'react';

import Brahma from '~/assets/imgs/thumb/brahma.jpg';
import Btg from '~/assets/imgs/thumb/btg.jpg';
import Bud from '~/assets/imgs/thumb/bud.jpg';
import Misc from '~/assets/imgs/thumb/experimentos.jpg';
import Inter from '~/assets/imgs/thumb/inter.jpg';
import Mercedes from '~/assets/imgs/thumb/mercedes.jpg';
import Panco from '~/assets/imgs/thumb/panco.jpg';
import Trident from '~/assets/imgs/thumb/trident.jpg';

import {
  Container,
  Project,
  ImageBackground,
  InfoBox,
  Title,
  Description,
} from './styles';

export default function Projects() {
  return (
    <Container>
      <Project>
        <a href="/projeto/brahma">
          <ImageBackground src={Brahma} alt="Brahma, Rótulos históricos" />
          <InfoBox>
            <Title>Brahma, Rótulos históricos</Title>
            <Description>Social Content</Description>
          </InfoBox>
        </a>
      </Project>
      <Project>
        <a href="projeto/trident">
          <ImageBackground src={Trident} alt="Trident, Masca que relaxa" />
          <InfoBox>
            <Title>Trident, Masca que relaxa</Title>
            <Description>Integrated Campaign</Description>
          </InfoBox>
        </a>
      </Project>
      <Project>
        <a href="/projeto/budweiser">
          <ImageBackground src={Bud} alt="Budweiser, Deixe que digam" />
          <InfoBox>
            <Title>Budweiser, Deixe que digam</Title>
            <Description>Integrated Campaign</Description>
          </InfoBox>
        </a>
      </Project>

      <Project>
        <a href="/projeto/inter">
          <ImageBackground src={Inter} alt="Inter" />
          <InfoBox>
            <Title>Inter&Co, Think simpler.Think Global.</Title>
            <Description>Integrated Campaign</Description>
          </InfoBox>
        </a>
      </Project>
      <Project>
        <a href="/projeto/mercedes">
          <ImageBackground src={Mercedes} alt="Mercedes" />
          <InfoBox>
            <Title>Mercedes-Benz</Title>
            <Description>7 Notas Campaign</Description>
          </InfoBox>
        </a>
      </Project>
      <Project>
        <a href="/projeto/panco">
          <ImageBackground
            src={Panco}
            alt="Panco, O Reino Encantado de Panfi"
          />
          <InfoBox>
            <Title>Panco, O Reino Encantado de Panfi</Title>
            <Description>Ilustration and UI/UX design</Description>
          </InfoBox>
        </a>
      </Project>
      <Project>
        <a href="/projeto/btg">
          <ImageBackground src={Btg} alt="Btg" />
          <InfoBox>
            <Title>BTG + business</Title>
            <Description>UX/UI Design</Description>
          </InfoBox>
        </a>
      </Project>
      <Project>
        <a href="/projeto/misc">
          <ImageBackground src={Misc} alt="Misc" />
          <InfoBox>
            <Title>Misc</Title>
          </InfoBox>
        </a>
      </Project>
    </Container>
  );
}
