import React from 'react';

import Header from '~/components/Header';

import Banner from '~/pages/Projects/Components/Banner';
import Nav from '~/pages/Projects/Components/Nav';

import btg_01 from '~/assets/imgs/btg/btg_01.png';
import btg_02 from '~/assets/imgs/btg/btg_02.png';
import btg_03 from '~/assets/imgs/btg/btg_03.png';
import btg_04 from '~/assets/imgs/btg/btg_04.png';
import btg_05 from '~/assets/imgs/btg/btg_05.png';
import btg_06 from '~/assets/imgs/btg/btg_06.png';
import btg_07 from '~/assets/imgs/btg/btg_07.png';
import btg_09 from '~/assets/imgs/btg/btg_09.png';
import btg_10 from '~/assets/imgs/btg/btg_10.png';
import btg_11 from '~/assets/imgs/btg/btg_11.png';
import btg_12 from '~/assets/imgs/btg/btg_12.png';
import banner from '~/assets/imgs/btg/btg_banner.png';
import btg_celular_01 from '~/assets/imgs/btg/btg_celular_01.png';
import btg_celular_02 from '~/assets/imgs/btg/btg_celular_02.png';
import btg_celular_03 from '~/assets/imgs/btg/btg_celular_03.png';
import btg_celular_04 from '~/assets/imgs/btg/btg_celular_04.png';

import Description from '../../Components/Description';
import Grid from '../../Components/Grid';
import HorizontalSlider from '../../Components/HorizontalSlider';
import PictureHole from '../../Components/PictureHole';

export default function Trident() {
  const HeaderConfig = {
    name: 'BTG + Business',
    job: 'UI/UX Design',
    description: (
      <>
        <p>
          In order to minimize the economic impact of the pandemic, BTG Pactual
          created a digital platform called BTG+ Business for providing credit
          to small and medium-sized businesses. I was responsible for the design
          direction of this project and I had the chance to work on various
          aspects of design, including UI/UX, icons, design systems, and art
          direction. The platform offers various financial services, such as
          advances on receivables, bank slips, and transfers. However, its
          primary function is to offer credit, which helps to sustain jobs,
          businesses, and ultimately the economy.
        </p>
      </>
    ),
  };
  return (
    <>
      <Header config={HeaderConfig} />
      <Banner src={banner} alt="Banner BTG" />
      <Grid
        list={[
          <img src={btg_01} alt="BTG Interface01" />,
          <img src={btg_02} alt="BTG Interface 02" />,
        ]}
        columns={{
          xl: 2,
          md: 1,
          sm: 1,
        }}
      />
      <Banner src={btg_03} alt="BTG" classes="noMarging" />
      <Banner src={btg_04} alt="BTG Tablet" classes="noMarging" />
      <PictureHole src={btg_05} background="#999" />
      <HorizontalSlider
        images={[
          { src: btg_celular_01, alt: 'BTG Mobile Interface 01' },
          { src: btg_celular_02, alt: 'BTG Mobile Interface 02' },
          { src: btg_celular_03, alt: 'BTG Mobile Interface 03' },
          { src: btg_celular_04, alt: 'BTG Mobile Interface 04' },
        ]}
      />

      <Banner src={btg_06} alt="BTG Mood Board" />
      <Banner src={btg_07} alt="BTG Fonts Board" />

      <Banner src={btg_09} alt="BTG Guidelines" />
      <Banner src={btg_10} alt="BTG types" />
      <Description
        contentList={[
          'Our icon family is inspired by the circle present in our logo: it has rounded corners and always has one of the lines open.',
        ]}
      />
      <Banner src={btg_11} alt="BTG icons" />
      <Description
        contentList={[
          'To create the icons, use a size of 24x24 px within a 48x48 px artboard. From this proportion, always consider rounded corners between 1 px and 4 px. To keep the entire icon family uniform, consider a stroke thickness of 2 px. In order to bring the same personality present in the logo, one of the lines that make up the icon should always be open.',
        ]}
      />
      <Banner src={btg_12} alt="BTG icons guide" />

      <Nav prev="/projeto/panco" next="/projeto/misc" />
    </>
  );
}
