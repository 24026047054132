import React from 'react';
import { Switch, Route } from 'react-router-dom';

import About from '~/pages/About';
import Home from '~/pages/Home';
import Projects from '~/pages/Projects';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/sobre" exact component={About} />
      <Route path="/projeto/:slug" exact component={Projects} />
    </Switch>
  );
}
