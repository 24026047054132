import Brahma from './Brahma';
import BTG from './btg';
import Budweiser from './Budweiser';
import Hp from './Hp';
import Inataa from './Inataa';
import Inter from './Inter';
import Mercedes from './Mercedes';
import Misc from './Misc';
import Panco from './Panco';
import Trident from './Trident';
import Unovation from './Unovation';

const Projects = {
  trident: Trident,
  hp: Hp,
  panco: Panco,
  budweiser: Budweiser,
  inataa: Inataa,
  brahma: Brahma,
  misc: Misc,
  unovation: Unovation,
  btg: BTG,
  inter: Inter,
  mercedes: Mercedes,
};

export default function Project({ slug }) {
  return Projects[slug]();
}
