import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { HideScroll } from 'react-hide-on-scroll';

import PropTypes from 'prop-types';

import Logo from '~/assets/svg/logo.svg';

import {
  Container,
  FixHeader,
  LogoNav,
  Menu,
  MenuBox,
  MenuItem,
} from './styles';

function Nav({ open, close }) {
  const [menuClose, setMenuClose] = useState(false);

  return (
    <Container className={`${menuClose && 'open'}`}>
      <HideScroll variant="down">
        <FixHeader>
          <LogoNav href="/" className={`${menuClose ? open : close}`}>
            <img src={Logo} alt="Alan Madeira" />
          </LogoNav>
          <Menu
            onClick={() => setMenuClose(!menuClose)}
            className={menuClose && 'close'}
          >
            <span className={`${menuClose ? open : close}`} />
          </Menu>
        </FixHeader>
      </HideScroll>

      <MenuBox className={menuClose && 'open'}>
        <MenuItem href="/" delay="1000ms">
          Works
        </MenuItem>
        <MenuItem href="/sobre" delay="900ms">
          About me
        </MenuItem>
      </MenuBox>
    </Container>
  );
}
Nav.defaultProps = {
  open: 'dark',
  close: 'light',
};

Nav.propTypes = {
  open: PropTypes.string,
  close: PropTypes.string,
};

export default Nav;
