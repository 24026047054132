const Fonts = {
  sizes: {
    Small: '16px',
    Middle: '2wpx',
    Bigger: '40px',
  },
  family: {},
};

export default Fonts;
