import styled from 'styled-components';

import { Colors, Breakpoints, Spaces } from '~/styles/Metrics';

export const FooterBlock = styled.footer`
  display: grid;
  width: 80%;
  grid-template-columns: 1fr 1fr 1fr;
  ${Breakpoints.sm} {
    grid-template-columns: 1fr;
    padding: ${Spaces.BasePadding} 0;
  }
`;

export const FooterLogo = styled.a`
  display: flex;
  flex: 1;
  cursor: pointer;
  justify-content: flex-end;
  ${Breakpoints.sm} {
    justify-content: center;
  }
  img {
    height: 30px;
    filter: invert(1);
  }
`;

export const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  flex: 1;

  ${Breakpoints.sm} {
    margin: ${Spaces.BaseMargin} 0;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${Colors.White};
  display: block;
`;

export const Social = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  &.hidden {
    opacity: 0;
  }
  a {
    margin: 0 40px 0 0px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: ${Colors.White};
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    line-height: 13px;
    &:hover {
      color: ${Colors.Hover};
      text-decoration: underline;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  background: ${Colors.Black};
  padding: calc(${Spaces.BasePadding} * 4) 0;
  &.invert {
    background: ${Colors.White};
    ${Text} {
      color: ${Colors.Black};
    }
    ${Social} {
      a {
        color: ${Colors.Black};
      }
    }
  }
`;
