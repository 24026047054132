import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Container, ToogleImageBox, Image } from './styles';

function ToogleBanner({ images, delay }) {
  const [showImage, setShowImag] = useState(0);

  const Total = images.length;
  useEffect(() => {
    setInterval(() => {
      setShowImag(state => (state + 1 > Total ? 1 : state + 1));
    }, delay);
  }, [Total, delay]);

  return (
    <Container>
      <ToogleImageBox>
        {images.map(({ src, alt, id }) => (
          <Image
            src={src}
            alt={alt}
            key={id}
            className={showImage === id && 'show'}
          />
        ))}
      </ToogleImageBox>
    </Container>
  );
}

ToogleBanner.defaultProps = {
  delay: 1000,
};
ToogleBanner.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  delay: PropTypes.number,
};
export default ToogleBanner;
