import styled from 'styled-components';

import { Breakpoints } from '~/styles/Metrics';

export const Container = styled.div`
  width: 100vw;
  display: grid;
  gap: 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  ${Breakpoints.md} {
    grid-template-columns: 1fr 1fr;
  }
  ${Breakpoints.sm} {
    grid-template-columns: 1fr;
  }
`;

export const Project = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  cursor: pointer;
  a {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #000;
    overflow: hidden;
    cursor: pointer;
    img {
      transition: all 0.2s ease-out;
    }
    &:hover {
      img {
        transform: scale(1.5);
        opacity: 0.2;
      }
    }
  }
`;

export const ImageBackground = styled.img`
  width: 100%;
`;

export const InfoBox = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s;
  &:hover {
    opacity: 1;
    p,
    h3 {
      transform: scale(1);
    }
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  color: #fff;
  transition: all 0.2s ease-out;
  transform: scale(1.3);
  z-index: 2;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  font-size: 17px;
  font-weight: normal;
  color: #fff;
  z-index: 2;
  transition: all 0.2s ease-out;
  transform: scale(1.3);
`;
