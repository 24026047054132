import React from 'react';

import Header from '~/components/Header';

import Banner from '~/pages/Projects/Components/Banner';
import Description from '~/pages/Projects/Components/Description';
import Grid from '~/pages/Projects/Components/Grid';
import Nav from '~/pages/Projects/Components/Nav';

import Kv from '~/assets/imgs/panco/kv.jpg';
import Tela1 from '~/assets/imgs/panco/panco_01.jpg';
import Tela2 from '~/assets/imgs/panco/panco_02.jpg';
import Tela3 from '~/assets/imgs/panco/panco_03.jpg';
import Tela4 from '~/assets/imgs/panco/panco_04.jpg';
import Tela5 from '~/assets/imgs/panco/panco_05.jpg';
import Tela6 from '~/assets/imgs/panco/panco_06.jpg';
import Tablet from '~/assets/imgs/panco/panco_tablet.jpg';

export default function Panco() {
  const HeaderConfig = {
    name: 'Panco',
    label: ' O Reino Encantado de Panfi',
    job: 'Ilustration and UI/UX design',
    description: (
      <p>
        Panco decided for this project to illustrate stories of the Enchanted
        Kingdom of Panfi. To promote and boost sales during the launch of Panfi
        muffins, the brand built a children’s first platform through games and
        digital interactions alongside beautiful and magical illustrations with
        different scenarios and characters.
      </p>
    ),
  };
  return (
    <>
      <Header config={HeaderConfig} />
      <Banner src={Kv} alt="O Reino Encantado de Panfi" />

      <Grid
        list={[
          <img src={Tela1} alt="Ilustração Tela 1" />,
          <img src={Tela2} alt="Ilustração Tela 2" />,
          <img src={Tela3} alt="Ilustração Tela 3" />,
          <img src={Tela4} alt="Ilustração Tela 4" />,
          <img src={Tela5} alt="Ilustração Tela 5" />,
          <img src={Tela6} alt="Ilustração Tela 6" />,
        ]}
        columns={{
          xl: 2,
          md: 1,
        }}
      />

      <Description
        contentList={[
          'In addition to illustrating I had the opportunity to design the UI and UX project.',
        ]}
      />

      <Banner src={Tablet} alt="O Reino Encantado de Panfi" />
      <Nav prev="/projeto/mercedes" next="/projeto/btg" />
    </>
  );
}
