import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function Text({ children }) {
  return <Container>{children}</Container>;
}

Text.propTypes = {
  children: PropTypes.string.isRequired,
};
export default Text;
