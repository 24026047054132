import styled from 'styled-components';

import { Colors, Spaces } from '~/styles/Metrics';

export const NavLinkArrow = styled.div`
  height: 1px;
  color: ${Colors.Black};
  position: relative;
  background: currentcolor;
  transition: all 250ms ease-in-out;
  margin: ${Spaces.BaseMargin};
  width: 0;
  &.hover {
    width: 20%;
    opacity: 1;
  }
  &.hoverOut {
    align-self: flex-start;
  }

  &::after {
    content: '';
    border-left: 1px solid currentColor;
    border-top: 1px solid currentColor;
    transform: rotate(-45deg);
    transform-origin: left top;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Container = styled.a`
  border: 0;
  padding: calc(${Spaces.BasePadding} * 3) 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100vw;
  max-width: 100%;
  color: currentColor;
  position: relative;
  background: ${Colors.White};
  &::after {
    content: '';
    height: 30%;
    width: 1px;
    background: currentColor;
    position: absolute;
  }
  &.left {
    flex-direction: row;
    &::after {
      right: 0;
    }
  }
  &.right {
    flex-direction: row-reverse;
    ${NavLinkArrow} {
      transform: rotate(180deg);
    }
    &::after {
      left: 0;
    }
  }
`;

export const NavLinkText = styled.em`
  font-style: normal;
  font-size: 25px;
  font-weight: normal;
`;
