import React from 'react';

import PropTypes from 'prop-types';

import {
  Container,
  Title,
  Name,
  SubTitle,
  DescriptionBox,
  Description,
  Job,
} from './styles';

function Header({ config: { name, label, job, description } }) {
  return (
    <Container>
      <Title>
        <Name>{name}</Name>
        {label && <SubTitle>{label}</SubTitle>}
      </Title>

      <DescriptionBox>
        <Job>{job}</Job>
        <Description>{description}</Description>
      </DescriptionBox>
    </Container>
  );
}

Header.propTypes = {
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    job: PropTypes.string.isRequired,
    description: PropTypes.node,
  }).isRequired,
};

export default Header;
