import React, { useEffect } from 'react';
import { BsArrowUpRight } from 'react-icons/bs';

import Background from '~/assets/video/banner.webm';

import {
  Container,
  VideoBox,
  Video,
  InfoBox,
  Title,
  Text,
  Social,
  ArrowBox,
  Arrow,
} from './styles';

export default function Banner() {
  useEffect(() => {
    document.getElementById('Background').play();
  });
  return (
    <Container>
      <VideoBox>
        <Video id="Background" autoPlay loop src={Background} />
      </VideoBox>
      <InfoBox>
        <Title>
          Alan Madeira <br />
          Designer & Creative Director <br />
          based in São Paulo, Brazil.
        </Title>
        <Text style={{ fontSize: '22px' }}>
          I&apos;m available for nice projects & freelance opportunities.
        </Text>

        <Social>
          <a
            href="https://www.instagram.com/alanmadeira/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
            <BsArrowUpRight />
          </a>
          <a
            href="https://www.linkedin.com/in/alan-madeira-43b40b22/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
            <BsArrowUpRight />
          </a>
          <a
            href="mailto:hey@alanmadeira.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
            <BsArrowUpRight />
          </a>
        </Social>
        <ArrowBox>
          <div>
            <Arrow />
          </div>
        </ArrowBox>
      </InfoBox>
    </Container>
  );
}
