import styled, { keyframes } from 'styled-components';

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
  opacity: 0;
  animation: ${show} 1s linear;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
`;
