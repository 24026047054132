import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function Masonry({ columns, children }) {
  return <Container columns={columns}>{children}</Container>;
}

Masonry.defaultProps = {
  columns: {
    xl: 1,
    lg: null,
    md: null,
    sm: null,
    xs: null,
  },
};

Masonry.propTypes = {
  columns: PropTypes.shape({
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
  }),
  children: PropTypes.node.isRequired,
};
export default Masonry;
