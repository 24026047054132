import styled from 'styled-components';

import { Colors, Spaces, Breakpoints } from '~/styles/Metrics';

export const Container = styled.div`
  width: 100vw;
  background: ${Colors.Black};
`;

export const ParallaxContainer = styled.div`
  width: 100%;
  height: 90vh;
  background: ${Colors.ShadowBalck};
`;

export const Title = styled.h1`
  font-size: 90px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: ${Colors.White};
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${Breakpoints.sm} {
    font-size: 50px;
  }
  ${Breakpoints.xs} {
    font-size: 30px;
  }
`;

export const AboutBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  flex: 1;
  padding: 0 ${Spaces.BasePadding};
  flex-direction: column;
  color: ${Colors.White};
  margin: 100px auto calc(${Spaces.BaseMargin} * 2) auto;
`;

export const ContentBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  margin: 0;
  margin-top: 100px;
`;

export const SubTitle = styled.h4`
  font-size: 80px;
  line-height: 5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin: 0;
  ${Breakpoints.xs} {
    font-size: 1em;
  }
`;

export const Text = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 15px;
  align-self: flex-start;
  max-width: 100%;

  &.italic {
    font-style: italic;
    margin-bottom: 32px;
  }
  &.strong {
    font-weight: bold;
  }
  &.title {
    text-transform: uppercase;
    font-weight: bold;
  }
`;
export const InfoBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  ${Breakpoints.sm} {
    flex-direction: column;
  }
`;

export const ButtonBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
  ${Breakpoints.sm} {
    margin-bottom: calc(${Spaces.BaseMargin} * 2);
  }
`;

export const ListBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 55%;
  gap: calc(${Spaces.BaseMargin}*4) calc(${Spaces.BaseMargin}*2);
`;

export const Lista = styled.ul`
  list-style: none;
  width: calc(50% - (${Spaces.BaseMargin}*2));

  ${Breakpoints.md} {
    width: calc(100% - (${Spaces.BaseMargin}*2));
  }
  ${Breakpoints.sm} {
    width: calc(50% - (${Spaces.BaseMargin}*2));
  }
  ${Breakpoints.xs} {
    width: calc(100% - (${Spaces.BaseMargin}*2));
  }
  li {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const Highlight = styled.h3`
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: bold;
`;

export const MiniTitle = styled(Highlight)`
  font-family: 'Poppins', sans-serif;
  font-size: 23px;
  margin-bottom: 60px;
  ${Breakpoints.sm} {
    width: calc(80vh - (${Spaces.BasePadding} * 2));
    text-align: center;
  }
`;

export const Hr = styled.span`
  border-bottom: 1px solid #363636;
  width: 100%;
  margin: 100px 0;
`;

export const Social = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  a {
    margin: 0 40px 0 0px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: ${Colors.White};
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    line-height: 13px;
    &:hover {
      color: ${Colors.Hover};
    }
  }
`;

export const Button = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
  background: transparent;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
  gap: 5px;
  line-height: 13px;
  &:hover {
    color: ${Colors.Hover};
  }
`;

export const Big = styled.p`
  width: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 90px;
  font-weight: 500;
  margin: 80px 0;
`;
