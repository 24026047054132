import styled from 'styled-components';

import { Spaces, Breakpoints } from '~/styles/Metrics';

export const Container = styled.ul`
  display: grid;
  grid-gap: ${props => props.gap}px;
  width: 100%;
  max-width: 1600px;
  padding: 0 20px;
  margin: ${Spaces.BaseMargin} auto;
  list-style: none;
  grid-template-columns: repeat(
    ${props => props.columns && props.columns.xl},
    1fr
  );

  ${Breakpoints.lg} {
    grid-template-columns: repeat(
      ${props => props.columns && props.columns.lg},
      1fr
    );
  }
  ${Breakpoints.md} {
    grid-template-columns: repeat(
      ${props => props.columns && props.columns.md},
      1fr
    );
  }
  ${Breakpoints.sm} {
    grid-template-columns: repeat(
      ${props => props.columns && props.columns.sm},
      1fr
    );
  }
  ${Breakpoints.xs} {
    grid-template-columns: repeat(
      ${props => props.columns && props.columns.xs},
      1fr
    );
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  grid-column-start: span ${props => props.span || 1};
  & > * {
    display: flex;
    max-width: 100%;
    ${Breakpoints.sm} {
      width: 100%;
    }
  }
`;
