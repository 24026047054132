import React from 'react';

import PropTypes from 'prop-types';

import { Container, Image } from './styles';

function HorizontalSlider({ images }) {
  return (
    <Container>
      {images.map(({ src, alt }) => (
        <Image src={src} alt={alt} />
      ))}
    </Container>
  );
}
HorizontalSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default HorizontalSlider;
