import React from 'react';

import PropTypes from 'prop-types';

import NavLink from '~/components/NavLink';

import { Container } from './styles';

function Nav({ prev, next, prevText, nextText }) {
  return (
    <Container>
      <NavLink direction="left" href={prev}>
        {prevText}
      </NavLink>
      <NavLink direction="right" href={next}>
        {nextText}
      </NavLink>
    </Container>
  );
}

Nav.defaultProps = {
  prevText: 'Previous',
  nextText: 'Next',
};

Nav.propTypes = {
  prev: PropTypes.string.isRequired,
  next: PropTypes.string.isRequired,
  prevText: PropTypes.string,
  nextText: PropTypes.string,
};

export default Nav;
