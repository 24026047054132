import styled, { keyframes } from 'styled-components';

import { Colors, Spaces } from '~/styles/Metrics';

const entrada = keyframes`
  0% { top: -440px; opacity: 0; }
  40% { top: -440px; }
  50% { opacity: 0; }
  100% { top: 0px; opacity: 1; }
`;

const show = keyframes`
  from{transform: translateY(-200%);}
  to{transform: translateY(0%);}
`;

export const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(${Spaces.BaseMargin});
  max-width: 100%;
  min-width: 100vw;
  &.open {
    position: fixed;
  }
`;
export const FixHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-200%);
  animation: ${show} 500ms forwards;
  z-index: 20;
`;

export const LogoNav = styled.a`
  display: flex;
  flex: 1;
  cursor: pointer;
  z-index: 20;
  position: relative;
  align-items: center;
  justify-content: flex-start;

  img {
    transition: all 500ms linear;
    height: 35px;
  }

  &.light {
    img {
      filter: invert(1);
    }
  }
  &.dark {
    img {
      filter: invert(0);
    }
  }
`;

export const Menu = styled.span`
  cursor: pointer;
  margin-right: 10px;

  width: 30px;
  height: 30px;
  display: flex;
  justify-content: content;
  align-items: center;
  z-index: 20;
  position: relative;

  span {
    width: 100%;
    height: 3px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 250ms;
    transition: all 500ms linear;

    &.light {
      background: ${Colors.White};
      &::after,
      &::before {
        transition: all 500ms linear;
        background: ${Colors.White};
      }
    }
    &.dark {
      background: ${Colors.Black};
      &::after,
      &::before {
        background: ${Colors.Black};
      }
    }
    &::after {
      transition: all 250ms;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      margin: 0;
      padding: 0;
      top: -9px;
    }
    &::before {
      transition: all 100ms;
      content: '';

      width: 100%;
      height: 100%;
      position: absolute;
      margin: 0;
      padding: 0;
      bottom: -9px;
    }
  }
  &:not(.close):hover {
    span {
      &::before {
        bottom: -11px;
      }
      &::after {
        top: -11px;
      }
    }
  }
  &.close {
    span {
      background: transparent;
      &::after {
        width: 100%;
        top: 0px;
        transform: rotate(45deg);
      }
      &::before {
        width: 100%;
        bottom: 0px;
        transform: rotate(-45deg);
      }
    }
    &:hover {
      span {
        &::before {
          width: 90%;
        }
        &::after {
          width: 90%;
        }
      }
    }
  }
`;

export const MenuBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  flex-direction: column;
  top: -100vh;
  bottom: 0;
  left: 0;
  z-index: 15;
  background: ${Colors.ShadowHover};
  transition: top 0.5s;
  &.open {
    top: 0;
    a {
      animation-name: ${entrada};
    }
  }
`;

export const MenuItem = styled.a`
  font-size: 50px;
  font-weight: 500;
  color: ${Colors.Black};
  margin: 10px;
  cursor: pointer;
  position: relative;
  top: 0;
  animation-duration: ${props => (props.delay ? props.delay : '2s')};
  position: relative;
  transition: letter-spacing 150ms linear;
  &::after {
    content: '';
    height: 3px;
    background: currentColor;
    position: absolute;
    top: 50%;
    left: -10%;
    width: 0;
    transform: translate(0%, -50%);
    transition: width 250ms ease-in-out 100ms;
  }
  &:hover {
    letter-spacing: 11px;
    &::after {
      width: 120%;
    }
  }
`;
