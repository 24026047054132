import React from 'react';

import Header from '~/components/Header';

import Banner from '~/pages/Projects/Components/Banner';
import Grid from '~/pages/Projects/Components/Grid';
import Nav from '~/pages/Projects/Components/Nav';
import PictureHole from '~/pages/Projects/Components/PictureHole';

import Kv from '~/assets/imgs/inataa/banner.jpg';
import bg from '~/assets/imgs/inataa/bg.png';
import dog01 from '~/assets/imgs/inataa/dog_01.jpg';
import dog02 from '~/assets/imgs/inataa/dog_02.jpg';
import fonte from '~/assets/imgs/inataa/fonte.jpg';
import foto01 from '~/assets/imgs/inataa/foto_01.jpg';
import foto02 from '~/assets/imgs/inataa/foto_02.jpg';
import foto03 from '~/assets/imgs/inataa/foto_03.jpg';
import foto04 from '~/assets/imgs/inataa/foto_04.jpg';
import post from '~/assets/imgs/inataa/insta.jpg';
import logo01 from '~/assets/imgs/inataa/logo_01.jpg';
import logo02 from '~/assets/imgs/inataa/logo_02.jpg';

import { Container } from '../styles';

export default function Inataa() {
  const HeaderConfig = {
    name: 'Inataa',
    label: 'Terapia do amor incondicional',
    job: 'Branding',
    description: (
      <>
        <p>
          Inataa is a non-governmental organization that promotes well-being
          through therapy, activity and animal-assisted education in clinics and
          hospitals. The brand had to be clear and friendly, with a personality
          of friendly, that represented the love between the customers and man’s
          best friend: Our dogs. To promote the importance of therapy dogs on
          our wellbeing that visual campaign had to reflect and explore the
          connections between humans, nature and animals through the sense of
          hope, freedom, health, and vitality.
        </p>
      </>
    ),
  };
  return (
    <Container>
      <Header config={HeaderConfig} />
      <Banner src={Kv} alt="Inataa, Terapia do amor incondicional" />
      <Grid
        list={[
          <img src={logo01} alt="Logo Inataa grid" />,
          <img src={logo02} alt="Logo Inataa" />,
        ]}
        columns={{ xl: 2, sm: 1 }}
      />
      <Banner src={fonte} alt="Inataa Fonte" />
      <Banner src={dog01} alt="Inataa Dog 01" />
      <Banner src={dog02} alt="Inataa Dog 02" />
      <PictureHole src={bg} background="#33b180" />
      <Banner src={post} alt="Inataa Post Instagram" />
      <Grid
        list={[
          <img src={foto01} alt="Inataa Foto 01" />,
          <img src={foto02} alt="Inataa Foto 02" />,
          <img src={foto03} alt="Inataa Foto 03" />,
          <img src={foto04} alt="Inataa Foto 04" />,
        ]}
        columns={{ xl: 2, sm: 1 }}
      />
      <Nav prev="/projeto/inter" next="/projeto/panco" />
    </Container>
  );
}
