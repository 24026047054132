import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function Box({ background, children, style }) {
  return (
    <Container background={background} style={style}>
      {children}
    </Container>
  );
}

Box.defaultProps = { background: 'transparent', style: {} };

Box.propTypes = {
  style: PropTypes.objectOf,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Box;
