import React from 'react';

import Header from '~/components/Header';

import Banner from '~/pages/Projects/Components/Banner';
import Box from '~/pages/Projects/Components/Box';
import Description from '~/pages/Projects/Components/Description';
import Grid from '~/pages/Projects/Components/Grid';
import Nav from '~/pages/Projects/Components/Nav';
import Video from '~/pages/Projects/Components/Video';

import InterArrow from '~/assets/imgs/inter/arrow.png';
import interBanner from '~/assets/imgs/inter/banner_01.jpg';
import interActionBanner from '~/assets/imgs/inter/banner_02.jpg';
import interMobile1 from '~/assets/imgs/inter/interMobile_1.jpg';
import interMobile2 from '~/assets/imgs/inter/interMobile_2.jpg';
import interMobile3 from '~/assets/imgs/inter/interMobile_3.jpg';
import Post02 from '~/assets/imgs/inter/post2.jpg';
import Post04 from '~/assets/imgs/inter/post4.jpg';
import ivete1 from '~/assets/imgs/inter/video/ivete_01.mp4';
import ivete2 from '~/assets/imgs/inter/video/ivete_02.mp4';
import ivete3 from '~/assets/imgs/inter/video/ivete_03.mp4';
import ivete4 from '~/assets/imgs/inter/video/ivete_04.mp4';
import Post01 from '~/assets/imgs/inter/video/post_insta_01.mp4';
import Post03 from '~/assets/imgs/inter/video/post_insta_03.mp4';

import { Container } from '../styles';

export default function Inter() {
  const HeaderConfig = {
    name: 'Inter&Co',
    label: 'Think simpler.Think Global.',
    job: 'Integrated Campaign',
    description: (
      <>
        <p>
          I had the opportunity to work in synergy with the startup Usend, which
          was acquired by Banco Inter, where we initiated the
          internationalization of the bank and the IPO on Nasdaq (INTR) -
          Inter&Co through acquisition campaigns, social media initiatives, and
          the expansion of the Inter&Co brand and Super App in the American
          market.
        </p>
      </>
    ),
  };
  return (
    <Container>
      <Header config={HeaderConfig} />
      <Box
        background="#ff7c00"
        style={{ display: 'flex', flexDirection: 'column', paddingTop: 0 }}
      >
        <Banner src={interBanner} alt="Inter Banner" classes="noMarging" />
      </Box>
      <Banner
        src={interActionBanner}
        alt="Inter Banner Interaction"
        classes="noMarging"
      />
      <Box
        background="#EFEEEC"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Grid
          listStyle={{ background: '#fff' }}
          gap={10}
          list={[
            <Video src={Post01} autoPlay />,
            <img src={Post02} alt="" />,
            <Video src={Post03} autoPlay />,
            <img src={Post04} alt="" />,
          ]}
          columns={{
            xl: 4,
            md: 2,
            sm: 1,
          }}
        />
        <Description
          style={{ margin: '20px 0 0 0' }}
          contentList={[
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <img src={InterArrow} alt="" style={{ width: '20px' }} />
              Here are some social media assets, including posts from our
              official sponsorship of NYCFC and the Inter&Co brand launch video.
            </span>,
          ]}
        />
      </Box>
      <Description
        contentList={[
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '10px',
            }}
          >
            <img src={InterArrow} alt="" style={{ width: '20px' }} />
            We strategically utilized the renowned singer Ivete Sangalo, who
            boasts 35.7 million followers, to target the Latino audience in
            Florida, Orlando, and New York. Our collaboration with Ivete
            involved the development of various key visuals (KVs) for
            activations, out-of-home (OOH) pieces, social media campaigns, and
            more.
          </span>,
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <img
              src={InterArrow}
              alt=""
              style={{ visibility: 'hidden', width: '20px' }}
            />
            By leveraging Ivete Sangalo&apos;s immense popularity and influence,
            we aimed to establish a strong connection with the Latino community
            in the targeted locations. The KVs we created played a crucial role
            in capturing attention and effectively communicating our brand
            message. These visuals showcased the partnership between Inter&Co
            and Ivete Sangalo, emphasizing the unique value proposition offered
            by our Super App.
          </span>,
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <img
              src={InterArrow}
              alt=""
              style={{ visibility: 'hidden', width: '20px' }}
            />
            This enabled us to engage with her extensive fan base and generate
            excitement around Inter&Co. Through compelling content, exclusive
            promotions, and behind-the-scenes glimpses, we fostered a sense of
            authenticity and built a loyal following among our target audience.
          </span>,
        ]}
      />
      <Banner src={interMobile1} alt="Inter Banner Inter Mobile 1" />
      <Banner src={interMobile2} alt="Inter Banner Inter Mobile 2" />
      <Banner src={interMobile3} alt="Inter Banner Inter Mobile 3" />

      <Box
        background="#EFEEEC"
        style={{ display: 'flex', flexDirection: 'column', paddingTop: 0 }}
      >
        <Description
          style={{ marginBottom: 0 }}
          contentList={[
            <span
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <img src={InterArrow} alt="" style={{ width: '20px' }} />
              Here is a compiled sequence of gifs featuring Ivete Sangalo
            </span>,
          ]}
        />
        <Grid
          list={[
            <Video src={ivete1} />,
            <Video src={ivete2} />,
            <Video src={ivete3} />,
            <Video src={ivete4} />,
          ]}
          gap={16}
          columns={{
            xl: 4,
            md: 2,
            sm: 1,
          }}
        />
      </Box>

      <Nav prev="/projeto/budweiser" next="/projeto/mercedes" />
    </Container>
  );
}
