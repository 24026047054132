import React from 'react';
import { BsArrowUpRight } from 'react-icons/bs';
import { Parallax } from 'react-parallax';

import Footer from '~/components/Footer';
import Nav from '~/components/Nav';

import AboutBackground from '~/assets/imgs/grafite.jpg';

import {
  Container,
  ParallaxContainer,
  Title,
  AboutBox,
  SubTitle,
  ContentBox,
  Text,
  InfoBox,
  Highlight,
  MiniTitle,
  ListBox,
  ButtonBox,
  Lista,
  Button,
  Social,
  Hr,
  Big,
} from './styles';

export default function Sobre() {
  return (
    <Container>
      <Nav />

      <Parallax
        bgImage={AboutBackground}
        bgImageAlt="Alan Madeira Grafite"
        strength={300}
      >
        <ParallaxContainer>
          <Title>About me</Title>
        </ParallaxContainer>
      </Parallax>
      <AboutBox>
        <SubTitle>
          With 10+ years of professional production and creative experience.
        </SubTitle>
        <ContentBox>
          <div
            style={{
              maxWidth: 'calc(55% - 32px)',
              marginRight: '32px',
            }}
          >
            <Text>
              I have acquired a vast amount of knowledge and experience that
              makes up my repertoire. With the ever-evolving technology, I
              continually seek opportunities to explore and improve my skills,
              always looking for innovative approaches.
            </Text>

            <Text>
              Innovation, creativity, and illustration are not random processes
              for me. I approach every project with a purpose and a clear
              understanding of the objectives. Once I have a thorough
              understanding of the task at hand, I research, plan, and execute
              using creative solutions that prioritize problem-solving and
              delivering results. For me, the ultimate goal is to achieve the
              desired results, whether it is designing a single email marketing
              piece or an entire e-commerce website.
            </Text>

            <Text>
              I am committed to improving my skills every day, and I am
              fortunate to work with significant clients in the digital agency
              where I currently work. My regular activities include developing
              campaigns, social media content, illustrations, key visuals, and
              other projects for major clients such as Mercedes-benz, Budweiser,
              Inter&Co, HP, Trident, and more.
            </Text>

            <Text>
              In my free time, I enjoy exploring various forms of artistic
              expressions, and graffiti is one that stands out for me. I also
              enjoy digital illustration, where I can blend my graphic urban
              background style with my digital design skills.
            </Text>
          </div>
        </ContentBox>

        <Hr />

        <InfoBox>
          <ButtonBox>
            <div>
              <Button href="/pdf/AlanMadeira-resume-2023.pdf" target="_blank">
                Resume Download
                <BsArrowUpRight />
              </Button>
              <Social>
                <a
                  href="https://www.instagram.com/alanmadeira/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                  <BsArrowUpRight />
                </a>
                <a
                  href="https://www.linkedin.com/in/alan-madeira-43b40b22/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                  <BsArrowUpRight />
                </a>
                <a
                  href="mailto:hey@alanmadeira.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Email
                  <BsArrowUpRight />
                </a>
              </Social>
            </div>
          </ButtonBox>
          <ListBox>
            <Lista>
              <li>
                <Highlight>Brands</Highlight>
              </li>

              <li>Inter&Co NASDAQ: INTR</li>
              <li>BTG Pactual</li>
              <li>Mercedes-Benz</li>
              <li>Budweiser</li>
              <li>Brahma</li>
              <li>HP</li>
              <li>Trident</li>
              <li>Panco</li>
              <li>And others</li>
            </Lista>
            <Lista>
              <li>
                <Highlight>Skills</Highlight>
              </li>
              <Text>
                Creative Concepting, Visual Design, Graphic Design, Branding &
                Identity, Illustration, Advertising, UI/UX Design, Motion
                Graphics, Social Midia.
              </Text>
            </Lista>
            <Lista>
              <li>
                <Highlight>Experience</Highlight>
              </li>
              <li>Inter&Co NASDAQ: INTR</li>
              <li>BTG Pactual</li>
              <li>Buzzfeed</li>
              <li>Edelman </li>
              <li>Africa DDB</li>
              <li>Wunderman Thompson</li>
            </Lista>
            <Lista>
              <li>
                <Highlight>Education</Highlight>
              </li>
              <li>Google UX Design Professional Certificate</li>
              <li>
                BA — Digital DesignAnhembi Morumbi / Laureate International
                Universities
              </li>
              <li>Cuca -School of creatives - São Paulo </li>
            </Lista>
          </ListBox>
        </InfoBox>
        <Hr />
        <div
          style={{
            alignSelf: 'flex-end',
            maxWidth: 'calc(55% - 32px)',
            marginRight: '32px',
          }}
        >
          <Text>
            <MiniTitle>Inspiration</MiniTitle>
          </Text>
          <Text style={{ fontSize: '15px' }}>
            &ldquo;Every self-respecting designer should do something. Come up
            with new ideas, dust down old ideas and place them in a new context.
            Silence the cynics. Let the politicians know that wheeling and
            dealing achieve little. Prove that actions speak louder than words.
            Demonstrate the power of design. Designers can do more than make
            things pretty. Design is more than perfume, aesthetics, and
            trends.&rdquo;
          </Text>
          <Text className="italic" style={{ fontSize: '15px' }}>
            – Richard van der Laken, founder of What Design Can Do
          </Text>
          <Text>
            &ldquo;Design can help improve our lives in the present. Design
            thinking can help us chart a path to the future.&ldquo;
          </Text>
          <Text className="italic" style={{ fontSize: '15px' }}>
            – Tim Brown, co-Chair at IDEO, Vice Chair at kyu Collective
          </Text>
          <Text style={{ fontSize: '15px' }}>
            &ldquo;We&rsquo;re very simple people at Apple. We focus on making
            the world&rsquo;s best products and enriching people&rsquo;s lives.
            &rdquo;
          </Text>
          <Text className="italic" style={{ fontSize: '15px' }}>
            – Tim Cook, CEO of Apple
          </Text>
        </div>
        <Hr style={{ marginBottom: 0 }} />
        <Big>I hope you&rsquo;ll enjoy! Tks (:</Big>
      </AboutBox>

      <Footer hiddenlink />
    </Container>
  );
}
