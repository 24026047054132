import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Container, NavLinkArrow, NavLinkText } from './styles';

function NavLink({ direction, children, href }) {
  const [hover, setHover] = useState(false);
  return (
    <Container
      href={href}
      className={direction}
      onMouseOver={() => {
        setHover(true);
      }}
      onFocus={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
      onBlur={() => {
        setHover(false);
      }}
    >
      <NavLinkArrow className={`${hover && 'hover'} `} />
      <NavLinkText>{children}</NavLinkText>
    </Container>
  );
}

NavLink.propTypes = {
  direction: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
export default NavLink;
