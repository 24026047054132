import React from 'react';

import PropTypes, { bool, number } from 'prop-types';

import { Container, Item } from './styles';

function Grid({ list, columns, gap, listStyle, colSpan }) {
  return (
    <Container columns={columns} gap={gap}>
      {list.map((content, i) => (
        <Item style={listStyle} span={colSpan && colSpan[i]}>
          {content}
        </Item>
      ))}
    </Container>
  );
}

Grid.defaultProps = {
  columns: {
    xl: 1,
    lg: null,
    md: null,
    sm: null,
    xs: null,
  },
  colSpan: false,
  gap: 0,
  listStyle: {},
};
Grid.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  colSpan: PropTypes.oneOfType(PropTypes.arrayOf(number), bool),
  gap: PropTypes.number,
  listStyle: PropTypes.objectOf,
  columns: PropTypes.shape({
    xl: PropTypes.number,
    lg: PropTypes.number,
    md: PropTypes.number,
    sm: PropTypes.number,
    xs: PropTypes.number,
  }),
};

export default Grid;
