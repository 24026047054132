import React from 'react';

import Header from '~/components/Header';

import Banner from '~/pages/Projects/Components/Banner';
import Box from '~/pages/Projects/Components/Box';
import Description from '~/pages/Projects/Components/Description';
import Grid from '~/pages/Projects/Components/Grid';
import Nav from '~/pages/Projects/Components/Nav';
import Player from '~/pages/Projects/Components/Player';
import ToogleBanner from '~/pages/Projects/Components/ToogleBanner';

import banner01 from '~/assets/imgs/hp/banner/hp_01.jpg';
import banner02 from '~/assets/imgs/hp/banner/hp_02.jpg';
import banner03 from '~/assets/imgs/hp/banner/hp_03.jpg';
import banner04 from '~/assets/imgs/hp/banner/hp_04.jpg';
import Comentarios1 from '~/assets/imgs/hp/comentarios_hp_01.jpg';
import Comentarios2 from '~/assets/imgs/hp/comentarios_hp_02.jpg';
import Cadernos from '~/assets/imgs/hp/foto_HP.jpg';
import Ilustrcoes from '~/assets/imgs/hp/ilustra_HP.jpg';
import Maisa1 from '~/assets/imgs/hp/maisa_01.jpg';
import Maisa2 from '~/assets/imgs/hp/maisa_02.jpg';

export default function Hp() {
  const HeaderConfig = {
    name: 'Hp ',
    label: 'Volta às Aulas',
    job: 'Campain',
    description: (
      <>
        <p>
          Going back to school is a special time for children and teenagers of
          different ages. It’s about seeing what has changed, make new friends
          and catching up with the old ones. And more than that: the opportunity
          to start something new - again.
        </p>
        <p>
          For HP it’s the chance to build upon conversations about identity and
          self-expression reinforcing the importance of being original and
          innovative. In a campaign action for the DeskJet 3776 All-in-One
          printer and HP® Original Cartridges we encouraged people to show their
          unique points of view by asking them “What is the first impression you
          will make back to school?”
        </p>
      </>
    ),
  };
  return (
    <>
      <Header config={HeaderConfig} />
      <ToogleBanner
        images={[
          { src: banner01, alt: 'Volta às aulas Hp 01', id: 1 },
          { src: banner02, alt: 'Volta às aulas Hp 02', id: 2 },
          { src: banner03, alt: 'Volta às aulas Hp 03', id: 3 },
          { src: banner04, alt: 'Volta às aulas Hp 04', id: 4 },
        ]}
      />

      <Description
        contentList={[
          'The conversation that began with digital influencer Maísa Silva, sharing the anxieties and expectations for the first day after school, was amplified on HP Brazil’s social networks and reached the brand’s selling points, generating awareness and engaging the company. audience discovering the different features of the product as a way of expressing themselves, celebrating diversity and your best version of yourself.',
        ]}
      />
      <Box>
        <Player url="https://vimeo.com/283762717" />
      </Box>
      <Grid
        list={[
          <img src={Maisa2} alt="Maisa Hp video Frame 2" />,
          <img src={Maisa1} alt="Maisa Hp video Frame 1" />,
          <img src={Comentarios1} alt="Maisa Comentarios 1" />,
          <img src={Comentarios2} alt="Maisa Comentarios 2" />,
        ]}
        columns={{ xl: 2, md: 1 }}
      />
      <Description
        contentList={[
          'In addition, we do provide notebooks with 8 different arts on the POS. I had the opportunity to illustrate 3 of them.',
        ]}
      />
      <Banner src={Ilustrcoes} alt="Ilustrções das capas" />
      <Banner src={Cadernos} alt="Cadernos Hp" />

      <Nav prev="/projeto/budweiser" next="/projeto/mercedes" />
    </>
  );
}
