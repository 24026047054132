import React from 'react';
import { BsArrowUpRight } from 'react-icons/bs';

import PropTypes from 'prop-types';

import { Container, TextBox, Text, Social, FooterBlock } from './styles';

function Footer({ hiddenlink, invert }) {
  return (
    <Container className={invert ? 'invert' : ''}>
      <FooterBlock>
        <Social className={hiddenlink ? 'hidden' : ''}>
          <a
            href="https://www.instagram.com/alanmadeira/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
            <BsArrowUpRight />
          </a>
          <a
            href="https://www.linkedin.com/in/alan-madeira-43b40b22/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
            <BsArrowUpRight />
          </a>
          <a
            href="mailto:hey@alanmadeira.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
            <BsArrowUpRight />
          </a>
        </Social>
        <TextBox>
          <Text>Alan Madeira ©</Text>
        </TextBox>
        <div />
      </FooterBlock>
    </Container>
  );
}

Footer.defaultProps = {
  hiddenlink: false,
  invert: false,
};
Footer.propTypes = {
  hiddenlink: PropTypes.bool,
  invert: PropTypes.bool,
};

export default Footer;
