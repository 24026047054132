import React from 'react';

import Header from '~/components/Header';

import Banner from '~/pages/Projects/Components/Banner';
import Grid from '~/pages/Projects/Components/Grid';
import Nav from '~/pages/Projects/Components/Nav';

import brahmaBanner from '~/assets/imgs/brahma/banner.jpg';
import Post01 from '~/assets/imgs/brahma/brahma_01.png';
import Post02 from '~/assets/imgs/brahma/brahma_02.png';
import foto01 from '~/assets/imgs/brahma/foto_brahma_01.jpg';
import foto02 from '~/assets/imgs/brahma/foto_brahma_02.jpg';
import foto03 from '~/assets/imgs/brahma/foto_brahma_03.jpg';
import foto04 from '~/assets/imgs/brahma/foto_brahma_04.jpg';
import foto05 from '~/assets/imgs/brahma/foto_brahma_05.jpg';
import foto06 from '~/assets/imgs/brahma/foto_brahma_06.jpg';

export default function Brahma() {
  const HeaderConfig = {
    name: 'Brahma',
    label: 'Rótulos históricos',
    job: 'Social Content',
    description: (
      <>
        <p>
          To celebrate its 128 years Brahma beer decided to review its history
          and launch nine historic labels to celebrate important groundbreaking
          moments for the brand’s evolution. Each bottle’s label showcase an
          important date, both for Brazilians and for the brand. The digital
          strategy launched the special label posters on Instagram and made
          available for the audience.
        </p>
      </>
    ),
  };
  return (
    <>
      <Header config={HeaderConfig} />
      <Banner src={brahmaBanner} alt="Brahma Banner" />
      <Grid
        list={[
          <img src={foto01} alt="Brahma Foto 01" />,
          <img src={foto02} alt="Brahma Foto 02" />,
          <img src={foto03} alt="Brahma Foto 03" />,
          <img src={foto04} alt="Brahma Foto 04" />,
          <img src={foto05} alt="Brahma Foto 05" />,
          <img src={foto06} alt="Brahma Foto 06" />,
        ]}
        columns={{
          xl: 3,
          md: 2,
          sm: 1,
        }}
      />
      <Grid
        list={[
          <img src={Post01} alt="Brahma Postagem 01" />,
          <img src={Post02} alt="Brahma Postagem 02" />,
        ]}
        columns={{
          xl: 2,
          md: 1,
        }}
      />
      <Nav prev="/projeto/misc" next="/projeto/trident" />
    </>
  );
}
