import React from 'react';

import Header from '~/components/Header';

import Banner from '~/pages/Projects/Components/Banner';
import Nav from '~/pages/Projects/Components/Nav';

import mobile01 from '~/assets/imgs/unovation/01.jpg';
import mobile02 from '~/assets/imgs/unovation/02.jpg';
import mobile03 from '~/assets/imgs/unovation/03.jpg';
import mobile04 from '~/assets/imgs/unovation/04.jpg';
import banner1 from '~/assets/imgs/unovation/banner.jpg';
import buttons from '~/assets/imgs/unovation/bts.jpg';
import cores from '~/assets/imgs/unovation/cores.jpg';
import fontes from '~/assets/imgs/unovation/fontes.jpg';
import icons from '~/assets/imgs/unovation/icon.jpg';
import icons2 from '~/assets/imgs/unovation/icon2.jpg';
import Interface from '~/assets/imgs/unovation/interface-unovatio.jpg';
import menu01 from '~/assets/imgs/unovation/menu_01.jpg';
import menu02 from '~/assets/imgs/unovation/menu_02.jpg';
import menu03 from '~/assets/imgs/unovation/menu.jpg';

import Grid from '../../Components/Grid';
import HorizontalSlider from '../../Components/HorizontalSlider';

export default function Trident() {
  const HeaderConfig = {
    name: 'Unovation',
    job: 'UI/UX Design',
    description: (
      <>
        <p>
          Unovation is a technology startup. Alan provided design consultancy
          and the development of the brand’s new visual identity, applying the
          use of a sober color palette to transmit the feeling of tranquility,
          confidence and growth. In addition to a responsive UX and UI design
          for Unovation, Alan divided the process in four stages:
        </p>
        <p>
          - <strong>Identify</strong> the problem within the organization of the
          information displayed to give clarity on the company’s work.
        </p>
        <p>
          - <strong>Simplify</strong> and organize the main business
          informations and KPI’s.
        </p>
        <p>
          - <strong>Desktop and Mobile</strong> prototype versions of the
          platform.
        </p>
        <p>
          - <strong>Creation and development</strong> of design elements
          containing typography, colors, buttons and interactions.
        </p>
      </>
    ),
  };
  return (
    <>
      <Header config={HeaderConfig} />
      <Banner src={banner1} alt="Banner Unovation" />
      <Banner src={Interface} alt="Banner Unovation Interface" />
      <HorizontalSlider
        images={[
          { src: mobile01, alt: 'Unovation Mobile 01' },
          { src: mobile02, alt: 'Unovation Mobile 02' },
          { src: mobile03, alt: 'Unovation Mobile 03' },
          { src: mobile04, alt: 'Unovation Mobile 04' },
        ]}
      />
      <Grid
        list={[
          <img src={menu01} alt="Unovation Menu 01" />,
          <img src={menu02} alt="Unovation Menu 02" />,
          <img src={icons} alt="Unovation Icons" />,
          <img src={buttons} alt="Unovation Botões" />,
        ]}
        columns={{
          xl: 4,
          md: 2,
          sm: 1,
        }}
      />

      <Grid
        list={[
          <img src={menu03} alt="Unovation Menu" />,
          <img src={icons2} alt="Unovation Icons 2" />,
        ]}
        columns={{
          xl: 2,
          md: 1,
        }}
      />
      <Banner src={fontes} alt="Unovation Fontes" />
      <Banner src={cores} alt="Unovation Cores" />

      <Nav prev="/projeto/panco" next="/projeto/misc" />
    </>
  );
}
