import React from 'react';

import PropTypes from 'prop-types';

import Footer from '~/components/Footer';
import Nav from '~/components/Nav';

import Project from './Project';
import { Container } from './styles';

function Projects({ match }) {
  const { slug } = match.params;
  return (
    <Container>
      <Nav open="dark" close="dark" />
      <Project slug={slug} />
      <Footer invert />
    </Container>
  );
}

Projects.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape() }).isRequired,
};
export default Projects;
