import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.background && props.background};
  background-image: url(${props => props.src && props.src});
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-size: initial;
  background-attachment: fixed;
  background-position: center center;
`;

export const Hole = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: -1;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 120% auto;
  }
`;
