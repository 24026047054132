import styled from 'styled-components';

import { Spaces, Breakpoints } from '~/styles/Metrics';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 ${Spaces.BasePadding};
  margin: ${Spaces.BaseMargin} auto;
  ${Breakpoints.md} {
    display: flex;
    height: 100vh;
    justify-content: flex-start;
    overflow: initial;
    overflow-x: auto;
  }
`;

export const Image = styled.img`
  max-width: 320px;
  margin: 0 calc(${Spaces.BaseMargin});
  flex: 1;
  ${Breakpoints.md} {
    display: inline-block;
    margin: 0 calc(${Spaces.BaseMargin}) 0 calc(${Spaces.BaseMargin} - 20px);
  }
`;
