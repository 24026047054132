import styled from 'styled-components';

import { Breakpoints, Spaces } from '~/styles/Metrics';

export const Container = styled.div`
  transition: all 0.5s ease-in-out;
  column-gap: ${Spaces.BaseMargin};
  column-fill: initial;
  width: 100%;
  max-width: 1600px;
  margin: ${Spaces.BaseMargin} auto;
  transition: all 0.5s ease-in-out;
  column-count: ${props => props.columns && props.columns.xl};
  ${Breakpoints.lg} {
    column-count: ${props => props.columns && props.columns.lg};
  }
  ${Breakpoints.md} {
    column-count: ${props => props.columns && props.columns.md};
  }
  ${Breakpoints.sm} {
    column-count: ${props => props.columns && props.columns.sm};
  }
  ${Breakpoints.xs} {
    column-count: ${props => props.columns && props.columns.xs};
  }
  & > * {
    margin-bottom: ${Spaces.BaseMargin};
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }
`;
