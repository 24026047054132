import styled from 'styled-components';

import { Spaces } from '~/styles/Metrics';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: ${Spaces.BaseMargin} 0;
  &.noMarging {
    margin: 0;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;
