import styled, { keyframes } from 'styled-components';

import { Colors, Breakpoints, Spaces } from '~/styles/Metrics';

const entrada = keyframes`
  0% { top: 200px; opacity: 0; }
  40% { top: 200px; }
  50% { opacity: 0; }
  100% { top: 0px; opacity: 1; }
`;

const arrowAnimate = keyframes`
  0% { transform: translateY(-100%); }
  5% { transform: translateY(0%); }
  95% { transform: translateY(0%); }
  100% { transform: translateY(100%); }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 100vw;
  height: 100vh;
  background: ${Colors.Black};
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    background: ${Colors.ShadowBalck};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`;

export const VideoBox = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: absolute;
  left: 0;
  z-index: 1;
  top: 0;
`;

export const Video = styled.video`
  width: initial;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
`;

export const InfoBox = styled.div`
  z-index: 5;
  width: 80%;
  height: 50%;
  ${Breakpoints.md} {
    width: 90%;
    height: 70%;
  }
`;

export const Title = styled.h1`
  color: ${Colors.White};
  font-size: 70px;
  font-weight: 600;
  opacity: 1;
  animation-delay: 5s;
  animation: ${entrada} 2s;
  position: relative;
  top: 0;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  line-height: 5rem;
`;

export const SubTitle = styled.h2`
  opacity: 1;
  color: ${Colors.White};
  font-weight: normal;
  font-size: 24px;
  margin-bottom: 30px;
  animation-delay: 5s;
  animation: ${entrada} 2.3s;
  position: relative;
  line-height: 35px;
  top: 0;

  max-width: 100%;
`;

export const Text = styled.p`
  opacity: 1;
  color: ${Colors.White};
  margin: calc(${Spaces.BaseMargin}*2) 0;
  font-size: 30px;
  animation-delay: 5s;
  animation: ${entrada} 2.5s;
  position: relative;
  max-width: 100%;
  font-family: 'Poppins', sans-serif;
  line-height: 25px;
  top: 0;
`;

export const Social = styled.div`
  opacity: 1;
  margin-bottom: 20px;
  animation-delay: 5s;
  animation: ${entrada} 2.4s;
  position: relative;
  display: flex;
  top: 0;
  margin-top: calc(${Spaces.BaseMargin} * 5);
  a {
    margin: 10px 35px 10px 0;
    color: ${Colors.White};
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    line-height: 13px;
    &:hover {
      color: ${Colors.Hover};
      text-decoration: underline;
    }
  }
`;

export const ArrowBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: ${Spaces.BaseMargin};
  left: 0;
  height: 60px;
  overflow: hidden;
  &:hover {
    div {
      &::after {
        border-color: #ffffff;
        transform: scale(1.3);
      }
    }
  }
  div {
    height: 40px;
    width: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &::after {
      content: '';
      border: 2px solid transparent;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 0;
      height: calc(100% - 4px);
      width: calc(100% - 4px);
      transition: all 250ms ease-in-out;
      transform: scale(0.5);
    }
  }
`;
export const Arrow = styled.em`
  height: 90%;
  width: 90%;
  display: flex;
  position: relative;
  animation: ${arrowAnimate} 5s ease infinite;
  &::after {
    content: '';
    width: 2px;
    height: 100%;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, 0);
  }
  &::before {
    content: '';
    position: absolute;
    border-right-style: solid;
    border-bottom-style: solid;
    border-width: 2px;
    left: 50%;
    bottom: 0;
    transform: rotate(45deg) translate(-45%, 25%);
    width: 20%;
    border-color: #fff;
    height: 20%;
  }
`;
