import React from 'react';

import PropTypes from 'prop-types';

import { Container, Hole } from './styles';

function PictureHole({ src, background }) {
  return (
    <Container background={background} src={src}>
      <Hole />
    </Container>
  );
}

PictureHole.propTypes = {
  src: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};
export default PictureHole;
