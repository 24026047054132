import styled from 'styled-components';

import { Spaces } from '~/styles/Metrics';
import Colors from '~/styles/Metrics/colors';

export const Container = styled.div`
  display: flex;
  max-width: 1300px;
  width: 100%;
  padding: 0 20px;
  margin: calc(${Spaces.BaseMargin} + 70px) auto calc(${Spaces.BaseMargin} * 2)
    auto;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: calc(${Spaces.BaseMargin} * 5);
`;

export const Name = styled.h1`
  font-size: 121.5px;
  line-height: 131.5px;
  margin-bottom: 0;
  font-weight: bold;
  margin: 0 0 0 -9px;
  color: ${Colors.Black};
`;

export const SubTitle = styled.h2`
  color: ${Colors.Text};
  font-weight: normal;
  font-size: 54px;
  line-height: 54px;
  height: 100%;
  margin-bottom: 0;
`;

export const DescriptionBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const Job = styled.p`
  font-size: 36px;
  line-height: 46px;
  flex: 1;
`;

export const Description = styled.div`
  margin: 0 0 ${Spaces.BaseMargin} 0;
  color: ${Colors.Text};
  font-size: 15px;
  line-height: 25px;
  font-weight: normal;
  flex: 3;
`;
