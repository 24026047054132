import React from 'react';

import Header from '~/components/Header';

import Banner from '~/pages/Projects/Components/Banner';
import Box from '~/pages/Projects/Components/Box';
import Description from '~/pages/Projects/Components/Description';
import Grid from '~/pages/Projects/Components/Grid';
import HorizontalSlider from '~/pages/Projects/Components/HorizontalSlider';
import Nav from '~/pages/Projects/Components/Nav';
import ToogleBanner from '~/pages/Projects/Components/ToogleBanner';
import Video from '~/pages/Projects/Components/Video';

import BannerAzul from '~/assets/imgs/trident/banner-azul.jpg';
import BannerRosa from '~/assets/imgs/trident/banner-rosa.jpg';
import BannerRoxo from '~/assets/imgs/trident/banner-roxo.jpg';
import bannerAnimado01 from '~/assets/imgs/trident/banner/trident_01.jpg';
import bannerAnimado02 from '~/assets/imgs/trident/banner/trident_02.jpg';
import PostDispertador from '~/assets/imgs/trident/dispertador.png';
import PostZap from '~/assets/imgs/trident/erro_zap.png';
import PostEstagio from '~/assets/imgs/trident/estagio.png';
import PostPapel from '~/assets/imgs/trident/papel_comestivel.png';
import Storie1 from '~/assets/imgs/trident/storie_01.jpg';
import Storie2 from '~/assets/imgs/trident/storie_02.jpg';
import Storie3 from '~/assets/imgs/trident/storie_03.jpg';
import PostVideo from '~/assets/video/trident/instagram.webm';

export default function Trident() {
  const HeaderConfig = {
    name: 'Trident',
    label: 'Masca que relaxa',
    job: 'Integrated Campaign',
    description: (
      <p>
        The #MascaQueRelaxa (#ChewAndChill) integrated campaign focused on
        promoting intense relaxation moments during our everyday’s life.
        Combined relaxation with the flavor and freshness of Trident, the brand
        is showcased as your pocket partner always there to help you achieve
        this mission.
      </p>
    ),
  };
  return (
    <>
      <Header config={HeaderConfig} />
      <ToogleBanner
        images={[
          { src: bannerAnimado01, alt: 'Banner Animado 01', id: 1 },
          { src: bannerAnimado02, alt: 'Banner Animado 02', id: 2 },
        ]}
      />
      <Banner src={BannerAzul} alt="Banner Masca que relaxa menta" />
      <Banner src={BannerRosa} alt="Banner Masca que relaxa Melancia" />
      <Banner src={BannerRoxo} alt="Banner Masca que relaxa Blueberry" />
      <Description
        contentList={[
          'One of our challenges in social media is thinking about how to use Instagram Stories features in a relevant way for the user. On Kiss Day, turn content into service, offer songs to the crowd to kiss that kiss.',
          'Almost 70% of impacted people follow content to the end. In addition, 900 shares helped add in reach, impressions, and brand relevance.',
        ]}
      />
      <HorizontalSlider
        images={[
          { src: Storie1, alt: 'Trident Storie 1' },
          { src: Storie2, alt: 'Trident Storie 2' },
          { src: Storie3, alt: 'Trident Storie 3' },
        ]}
      />
      <Description
        contentList={['Other examples of content used during a campaign']}
      />
      <Grid
        list={[
          <img src={PostZap} alt="Trident Erro No ZAP" />,
          <img src={PostDispertador} alt="Trident Dispertador" />,
          <img src={PostEstagio} alt="Trident Estágio" />,
          <img src={PostPapel} alt="Trident Papel Comestivel" />,
        ]}
        columns={{
          xl: 4,
          md: 2,
          sm: 1,
        }}
      />
      <Box background="#e4e4e4">
        <Video src={PostVideo} />
      </Box>
      <Nav prev="/projeto/brahma" next="/projeto/budweiser" />
    </>
  );
}
