import React from 'react';

import Header from '~/components/Header';

import Box from '~/pages/Projects/Components/Box';
import Grid from '~/pages/Projects/Components/Grid';
import Nav from '~/pages/Projects/Components/Nav';

import mercedes2024_01 from '~/assets/imgs/mercedes/2024/mercedes_01.png';
import mercedes2024_02 from '~/assets/imgs/mercedes/2024/mercedes_02.png';
import mercedes2024_03 from '~/assets/imgs/mercedes/2024/mercedes_03.png';
import mercedes2024_04 from '~/assets/imgs/mercedes/2024/mercedes_04.png';
import mercedes2024_05 from '~/assets/imgs/mercedes/2024/mercedes_05.png';
import mercedes2024_06 from '~/assets/imgs/mercedes/2024/mercedes_06.png';
import mercedes2024_07 from '~/assets/imgs/mercedes/2024/mercedes_07.png';
import mercedes2024_08 from '~/assets/imgs/mercedes/2024/mercedes_08.png';
import mercedes10 from '~/assets/imgs/mercedes/mercedes-10.jpg';
import mercedes7 from '~/assets/imgs/mercedes/mercedes-7.jpg';
import mercedes8 from '~/assets/imgs/mercedes/mercedes-8.jpg';
import mercedes9 from '~/assets/imgs/mercedes/mercedes-9.jpg';
import mercedesBanner from '~/assets/imgs/mercedes/mercedes-banner.png';
import mercedesAudio1 from '~/assets/imgs/mercedes/mp3/Mercedes-Benz1.mp3';
import mercedesAudio2 from '~/assets/imgs/mercedes/mp3/Mercedes-Benz2.mp3';
import mercedesAudio3 from '~/assets/imgs/mercedes/mp3/Mercedes-Benz3.mp3';
import mercedesAudio4 from '~/assets/imgs/mercedes/mp3/Mercedes-Benz4.mp3';
import mercedesAudio5 from '~/assets/imgs/mercedes/mp3/Mercedes-Benz5.mp3';
import mercedesAudio6 from '~/assets/imgs/mercedes/mp3/Mercedes-Benz6.mp3';
import mercedesAudio7 from '~/assets/imgs/mercedes/mp3/Mercedes-Benz7.mp3';
import mercedesAudioVideo1 from '~/assets/imgs/mercedes/mp4/mercedesvideo1.mp4';

import Banner from '../../Components/Banner';
import { Container } from '../styles';

export default function Mercedes() {
  const HeaderConfig = {
    name: 'MERCEDES-BENZ',
    label: '',
    job: '7 Notas Campaign',
    description: (
      <>
        <p>
          Mercedes-Benz introduces the &#34;Sete Notas&#34; project, which aims
          to showcase the technologies found in different models of their
          automobiles in a &#34;simple and sensory&#34; manner.
        </p>
        <p>
          To accomplish this, the brand has enlisted the Brazilian music
          producer Dudu Marote. The initiative, which seeks to infuse a musical
          dimension into the experience of driving a Mercedes-Benz car, involves
          the creation of seven web series episodes that will be released on a
          weekly basis.
        </p>
        <p>
          The episodes intend to capture Dudu&apos;s initial reactions as he
          tests seven onboard technologies for the first time, namely MBUX,
          Adaptive Brake, EQ Boost, Active Parking Assist, Dynamic Select,
          Comfort, and Electrification. Following these experiences, the
          producer will translate his encounters into seven songs, specifically
          composed for Mercedes-Benz.
        </p>
      </>
    ),
  };
  return (
    <Container>
      <Header config={HeaderConfig} />
      <Box
        background="#000000"
        style={{ display: 'flex', flexDirection: 'column', paddingTop: 0 }}
      >
        <Banner
          src={mercedesBanner}
          alt="Mercedes Banner"
          classes="noMarging"
        />
      </Box>

      <Box background="#000000">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          controls
          style={{
            width: '100%',
            maxWidth: '1600px',
            objectFit: 'cover',
            height: '100%',
            maxHeight: '800px',
          }}
          src={mercedesAudioVideo1}
        />
      </Box>
      <Box background="#000000">
        <Grid
          gap={0}
          list={[
            <img
              src={mercedes2024_01}
              alt="Mercedes"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />,
            <img
              src={mercedes2024_02}
              alt="Mercedes"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />,
            <img
              src={mercedes2024_03}
              alt="Mercedes"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />,
            <img
              src={mercedes2024_04}
              alt="Mercedes"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />,
            <img
              src={mercedes2024_05}
              alt="Mercedes"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />,
            <img
              src={mercedes2024_06}
              alt="Mercedes"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />,
            <img
              src={mercedes2024_07}
              alt="Mercedes"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />,
            <img
              src={mercedes2024_08}
              alt="Mercedes"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />,
          ]}
          colSpan={[12, 12, 12, 12, 13, 35, 24, 24]}
          columns={{ xl: 48, md: 1 }}
        />
      </Box>
      <Grid
        gap={5}
        list={[
          <img src={mercedes7} alt="Mercedes " />,
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              gap: '5px',
            }}
          >
            <p style={{ fontSize: '16px', marginBottom: '60px' }}>
              Here is the sequence of songs, with the collaboration of Dudu
              Marote, that we have created to convey the sensations of driving
              Mercedes-Benz cars. It&apos;s an incredible musical journey that
              captures the essence of this unique experience.
            </p>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls style={{ width: '100%' }} src={mercedesAudio1} />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls style={{ width: '100%' }} src={mercedesAudio2} />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls style={{ width: '100%' }} src={mercedesAudio3} />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls style={{ width: '100%' }} src={mercedesAudio4} />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls style={{ width: '100%' }} src={mercedesAudio5} />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls style={{ width: '100%' }} src={mercedesAudio6} />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio controls style={{ width: '100%' }} src={mercedesAudio7} />
          </div>,
        ]}
        columns={{ xl: 2, md: 1 }}
      />
      <Grid
        list={[
          <img
            src={mercedes8}
            alt="Mercedes"
            style={{ objectFit: 'cover', height: '100%', width: '100%' }}
          />,
          <img src={mercedes9} alt="Mercedes" />,
          <img src={mercedes10} alt="Mercedes" />,
        ]}
        columns={{ xl: 3, md: 1 }}
      />
      <Nav prev="/projeto/inter" next="/projeto/panco" />
    </Container>
  );
}
