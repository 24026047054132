import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import '~/config/ReactotronConfig';

import Routes from '~/routes';
import GlobalStyle from '~/styles/global';

function App() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => prevProgress + 5);
    }, 400);

    setTimeout(() => {
      clearTimeout(timer);
      setProgress(100);
    }, 1500);
  }, []);
  return (
    <BrowserRouter>
      <GlobalStyle />
      <LoadingBar
        height={0}
        color="#FFC600"
        className="LoadingBar"
        progress={progress}
      />
      <Routes />
    </BrowserRouter>
  );
}

export default App;
