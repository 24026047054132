const Colors = {
  White: '#FFFFFF',
  Black: '#000000',
  Text: '#000000',
  Hover: '#FFC600',
  ShadowHover: 'rgba(255,168,0,.95)',
  ShadowBalck: 'rgba(0,0,0,.4)',
  BackgroundHighlight: '#E9E9E9',
};
export default Colors;
