import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function Video(props, { src, autoPlay, loop, controls }) {
  return (
    <Container
      src={src}
      autoPlay={autoPlay}
      loop={loop}
      controls={controls}
      {...props}
    />
  );
}

Video.defaultProps = {
  autoPlay: true,
  loop: true,
  controls: false,
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  controls: PropTypes.bool,
};

export default Video;
