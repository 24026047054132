import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

function Player({ url }) {
  return <Container url={url} />;
}

Player.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Player;
