import React from 'react';

import Header from '~/components/Header';

import Masonry from '~/pages/Projects/Components/Masonry';
import Nav from '~/pages/Projects/Components/Nav';
import Video from '~/pages/Projects/Components/Video';

import gridImage01 from '~/assets/imgs/misc/01.jpg';
import gridImage02 from '~/assets/imgs/misc/02.jpg';
import gridImage03 from '~/assets/imgs/misc/03.jpg';
import gridImage05 from '~/assets/imgs/misc/05.jpg';
import gridImage06 from '~/assets/imgs/misc/06.jpg';
import gridImage07 from '~/assets/imgs/misc/07.jpg';
import gridImage08 from '~/assets/imgs/misc/08.jpg';
import gridImage09 from '~/assets/imgs/misc/09.jpg';
import gridImage10 from '~/assets/imgs/misc/10.jpg';
import gridImage11 from '~/assets/imgs/misc/11.jpg';
import gridImage12 from '~/assets/imgs/misc/12.jpg';
import gridImage13 from '~/assets/imgs/misc/13.jpg';
import gridImage14 from '~/assets/imgs/misc/14.jpg';
import gridImage15 from '~/assets/imgs/misc/15.jpg';
import gridImage16 from '~/assets/imgs/misc/16.jpg';
import gridImage17 from '~/assets/imgs/misc/17.jpg';
import gridImage18 from '~/assets/imgs/misc/18.jpg';
import gridImage19 from '~/assets/imgs/misc/19.jpg';
import gridImage20 from '~/assets/imgs/misc/20.jpg';
import gridImage21 from '~/assets/imgs/misc/21.jpg';
import gridImage22 from '~/assets/imgs/misc/22.jpg';
import gridImage23 from '~/assets/imgs/misc/23.jpg';
import gridVideo01 from '~/assets/video/misc/01.webm';
import gridVideo02 from '~/assets/video/misc/02.webm';
import gridVideo03 from '~/assets/video/misc/03.webm';

import { Container } from '../styles';

export default function Misc() {
  const HeaderConfig = {
    name: 'Selection of illustrations, arts, KVs, studies and experiments',
    label: '',
    description: <p />,
  };
  return (
    <Container>
      <Header config={HeaderConfig} />
      <Masonry
        columns={{
          xl: 3,
          md: 2,
          sm: 1,
        }}
      >
        <Video src={gridVideo01} />
        <img src={gridImage01} alt="" />
        <img src={gridImage02} alt="" />
        <img src={gridImage03} alt="" />
        <Video src={gridVideo02} />
        <img src={gridImage05} alt="" />
        <img src={gridImage06} alt="" />
        <img src={gridImage07} alt="" />
        <img src={gridImage08} alt="" />
        <img src={gridImage09} alt="" />
        <img src={gridImage10} alt="" />
        <img src={gridImage11} alt="" />
        <img src={gridImage12} alt="" />
        <img src={gridImage13} alt="" />
        <img src={gridImage14} alt="" />
        <img src={gridImage15} alt="" />
        <img src={gridImage16} alt="" />
        <img src={gridImage17} alt="" />
        <img src={gridImage18} alt="" />
        <img src={gridImage19} alt="" />
        <img src={gridImage20} alt="" />
        <img src={gridImage21} alt="" />
        <img src={gridImage22} alt="" />
        <img src={gridImage23} alt="" />
        <Video src={gridVideo03} />
      </Masonry>
      <Nav prev="/projeto/btg" next="/projeto/brahma" />
    </Container>
  );
}
