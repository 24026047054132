import styled from 'styled-components';

import ReactPlayer from 'react-player';

import { Spaces } from '~/styles/Metrics';

export const Container = styled(ReactPlayer)`
  max-width: 1080px;
  width: 100% !important;
  min-width: 300px;

  height: initial !important;
  padding: 0 ${Spaces.BasePadding};
  div {
    position: relative;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    padding-bottom: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
