import React from 'react';

import PropTypes from 'prop-types';

import { Container, Image } from './styles';

function Banner({ src, alt, classes, ...rest }) {
  return (
    <Container className={classes} {...rest}>
      <Image src={src} alt={alt} />
    </Container>
  );
}

Banner.defaultProps = {
  classes: '',
};

Banner.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

export default Banner;
