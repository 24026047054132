import styled from 'styled-components';

import { Spaces } from '~/styles/Metrics';

export const Container = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.background && props.background};
  padding: calc(${Spaces.BasePadding} * 3) 0;
`;
